import { version } from './version';

export const environment = {
  BASE_URL: 'https://dte.dev.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '',
  schema: '',
  groupId: '',
  analiseGeral: '',
  analiseUsuarios: '',
  analiseComunicados: '',
  analiseProcurador: '',
  analiseEventos: ''
};
